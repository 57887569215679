<template>
	<div id="ellipsis1" class="myEllipsis">...</div>
</template>

<script>

export default {
  name: "Elipsis",
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@mds/constants";
@import "@mds/fonts";
@import "@mds/typography";
@import "@mds/link";

.myEllipsis {
	display: inline-block;
	justify-content: center;
	font-weight: bold;
	line-height: 5px;
	text-align: center;
	vertical-align: middle;
	align-items: center;
	width: $mds-space-2-x;
	height: $mds-space-2-x;	
	margin: 0 $mds-space-1-x;
	color: $mds-text-color-secondary;	
	border-radius: $mds-space-2-x;
	cursor: pointer;
	border: $mds-border-separator-on-dark;
}

</style>